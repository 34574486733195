<template>
  <m-widget-container ref="calendarContainer" class="d-flex flex-column justify-center">
    <v-card-title
      v-if="title"
      ref="titleContainer"
      class="text-center justify-center pb-1"
    >
      <h1 class="headline py-1 px-3 font-weight-bold d-flex align-center">
        {{ title }}
      </h1>
    </v-card-title>
    <v-card-text class="align-center pa-0">
      <swiper
        v-if="items.length"
        ref="swiper"
        :options="swiperOptions"
        class="m-swiper"
        :style="`--swiper-pagination-padding: ${pagination ? '30px' : 'initial'}; --card-scale: ${scaleFactor}`"
      >
        <swiper-slide
          v-for="(item, i) in upcomingEvents"
          :key="i"
        >
          <v-card
            class="mx-1 scaled-card"
            :color="cardColor"
            :shaped="cardShaped"
            :dark="cardDark"
            :flat="cardFlat"
            @click="activeItem = i"
          >
            <v-card-title class="pb-3 justify-center text-center two-line" style="word-break: break-word;">
              {{ item.name }}
            </v-card-title>
            <div class="title text-center pb-0">
              {{ item.start | formatDate('MMMM') }}
            </div>
            <v-card-title class="display-4 pt-0 font-weight-bold justify-center">
              {{ item.start | formatDate('DD') }}
            </v-card-title>
            <v-card-title class="headline justify-center pt-0">
              {{ item.start | formatDate('HH:mm') }} - {{ item.end | formatDate('HH:mm') }}
            </v-card-title>
          </v-card>
        </swiper-slide>
        <div
          v-if="pagination"
          slot="pagination"
          class="swiper-pagination primary-swiper-pagination"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
        <div
          slot="button-next"
          class="swiper-button-next"
        />
      </swiper>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="60vw"
      scrollable
    >
      <v-card
        v-if="selectedItem"
        :color="dialogColor"
        :dark="dialogDark"
      >
        <v-card-title class="headline font-weight-bold d-flex justify-space-between align-center flex-nowrap">
          {{ selectedItem.name }}
          <v-btn
            fab
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ selectedItem.start | formatDate('DD MMMM HH:mm') }} - {{ selectedItem.end | formatDate('HH:mm') }}
        </v-card-subtitle>
        <v-card-text class="body-1 mt-n3" style="white-space: pre-line;">
          {{ selectedItem.description }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </m-widget-container>
</template>

<script>
import moment from 'moment'
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MCalendar',
  mixins: [mWidgetMixin],
  props: {
    title: {
      type: String,
      default: () => ''
    },
    items: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [String, Number],
      default: () => '3'
    },
    cardColor: {
      type: String,
      default: () => null
    },
    cardDark: {
      type: Boolean,
      default: () => false
    },
    cardShaped: {
      type: Boolean,
      default: () => false
    },
    cardFlat: {
      type: Boolean,
      default: () => false
    },
    dialogColor: {
      type: String,
      default: () => null
    },
    dialogDark: {
      type: Boolean,
      default: () => false
    },
    pagination: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      dialog: false,
      activeItem: null,
      swiperOptions: {
        slidesPerView: this.cols,
        slidesPerGroup: 1,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      scaleFactor: 1
    }
  },
  computed: {
    selectedItem() {
      return this.activeItem !== null ? this.upcomingEvents[this.activeItem] : null
    },
    upcomingEvents() {
      const today = moment().format('YYYY-MM-DD')
      return this.items.filter(x => x.start >= today).sort((a, b) => {
        return this.getMoment(a.start).unix() - this.getMoment(b.start).unix()
      })
    }
  },
  watch: {
    selectedItem() {
      this.dialog = this.selectedItem !== null
    },
    dialog(newValue) {
      if (!newValue) {
        this.activeItem = null
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$refs.calendarContainer.$el.offsetHeight)
      console.log(this.$refs.swiper.$el.offsetHeight)
      // this.scaleFactor = (this.$refs.calendarContainer.$el.offsetHeight / this.$refs.swiper.$el.offsetHeight).toFixed(2)
    })
  }
}
</script>

<style lang="sass">
  .white-space-pre
    white-space: pre-line
  .v-card__title.two-line
    height: 3rem
    line-height: 1.4rem
  .m-swiper
    .swiper-wrapper
      padding-bottom: var(--swiper-pagination-padding)
    .swiper-pagination
      bottom: var(--swiper-pagination-padding)
  .v-card.scaled-card
    // transform: scale(var(--card-scale))
</style>
